<template>
    <div class="row">
        <h2 class="section-heading m-b-md"> {{$t('global.site', {text: 'Statistics'})}}</h2>
        <MessageCard :alertType="'indicator-info'" :alertTitle="$t('claims.new.comments')" :alertText="$t('stats.global.alert_message')" class="m-b-md"/>

        <!-- Select Client -->
        <div class="col-xl-4 m-b-md">
            <div class="form-group">
                <label class="form-label" for="site"> {{$t('global.select_client')}}</label>
                <select id="site" class="form-select" v-model="selectedClient" @change="getSites">
                    <option v-for="client in clients" :value="client.id" :key="client.id">{{client.name}}</option>
                </select>
            </div>
        </div>

        <!-- Select Site -->
        <div class="col-xl-4 m-b-md">
            <div class="form-group">
                <label class="form-label" for="site"> {{$t('dashboard.select_site')}}</label>
                <select id="site" class="form-select" v-model="selectedSite" @change="loadData">
                    <option v-for="site in sites" :value="site.id" :key="site.id">{{site.name}}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <stats-card :title="$t('stats.one.title')" :statsData="stats.escalated"></stats-card>
            <stats-card :title="$t('stats.two.title')" :statsData="stats.driveoffFirstLetter"></stats-card>
            <stats-card :title="$t('stats.three.title')" :statsData="stats.driveoffSecondLetter"></stats-card>
            <stats-card :title="$t('stats.four.title')" :statsData="stats.nmopDebtCollection"></stats-card>
            <stats-card :title="$t('stats.five.title')" :statsData="stats.driveoffDebtCollection"></stats-card>
            <stats-card :title="$t('stats.six.title')" :desc="$t('stats.six.desc')" :statsData="stats.blacklist"></stats-card>
        </div>
    </div>
</template>

<script>
import siteService from '../../services/site.service';
import MessageCard from '../../components/MessageCard.vue';
import StatsCard from '../../components/StatsCard.vue';
import {DATES} from '../../../src/constants/constants';
import statsService from '../../services/stats.service';

export default {
    name: 'SiteStatistics',
    mounted() {
        this.getSites();
        this.clients = this.$store.state.auth.user.clients;
        this.selectedClient = this.clients[0].id;
        // this.getEscalated();
        // this.getDriveoffFirstLetter();
        // this.getDriveoffSecondLetter();
        // this.getNmopCollection();
        // this.getdriveoffCollection();
        // this.getBlacklistCount();
        this.loadData();
    },
    components: {
        MessageCard,
        StatsCard
    },
    data() {
        return {
            sites: [],
            clients: [],
            selectedSite: -1,
            selectedClient: -1,
            stats: {
                escalated: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffFirstLetter: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffSecondLetter: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                nmopDebtCollection: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffDebtCollection: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                blacklist: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                anprSites: {
                    count: 0,
                    details: []
                },
                tabletOnly: {
                    count: 0,
                    tablet_sites: []
                },
                portalOnly: {
                    count: 0,
                    portal_sites: []
                }
            },
            dates: DATES,
            loading: true
        }
    },
    methods: {
        getSites() {
            siteService.getSites(1, this.$store.state.auth.user.selectedClient, {name:null, postcode: null})
            .then(response => {
                this.sites = response.data.sites;
                this.selectedSite = this.sites[0].id;
            })
        },
        getEscalated() { 
            for(let stat in this.stats.escalated) {
                statsService.getEscalated(this.selectedClient, this.selectedSite, this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.escalated[stat] = response.data.claim_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load escalated claims count'), error);
                })
            }
        },
        getDriveoffFirstLetter() {
            for(let stat in this.stats.driveoffFirstLetter) {
                statsService.getLetterCount(this.selectedClient, this.selectedSite, 'driveoffnotice', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.driveoffFirstLetter[stat] = response.data.claims.length;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff first letter count'), error);
                })
            }
        },
        getDriveoffSecondLetter() {
            for(let stat in this.stats.driveoffSecondLetter) {
                statsService.getLetterCount(this.selectedClient, this.selectedSite, 'driveoffsecond', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.driveoffFirstLetter[stat] = response.data.claims.length;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff second letter count'), error);
                })
            }
        },
        getNmopCollection() {
            for(let stat in this.stats.nmopDebtCollection) {
                statsService.getNmopCollection(this.selectedClient, this.selectedSite, 'nmop', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.nmopDebtCollection[stat] = response.data.claim_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff second letter count'), error);
                })
            }
        },
        getdriveoffCollection() {
           for(let stat in this.stats.driveoffDebtCollection) {
                statsService.getNmopCollection(this.selectedClient, this.selectedSite, 'driveoff', this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.driveoffDebtCollection[stat] = response.data.claim_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load driveoff second letter count'), error);
                })
            } 
        },
        getBlacklistCount() {
            for(let stat in this.stats.blacklist) {
                statsService.getBlacklistCount(this.selectedClient, this.selectedSite, this.dates[stat].start_date, this.dates[stat].end_date)
                .then(response => {
                    this.stats.blacklist[stat] = response.data.blacklist_count;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load blacklisted vehicle count'), error);
                })
            }
        },
        loadData() {
            this.getEscalated();
            this.getDriveoffFirstLetter();
            this.getDriveoffSecondLetter();
            this.getNmopCollection();
            this.getdriveoffCollection();
            this.getBlacklistCount();
        }
    },
    computed: {
        site_name: function() {
            if(this.selectedSite === -1) return ''; 
            return this.sites.filter(site => site.id === this.selectedSite)[0].name;
        }
    }
}
</script>

<style>

</style>